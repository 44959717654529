import {Dispatch} from "redux";

import {IFetchContextRoute} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";

export const redirectOnList404 =
    (route: IFetchContextRoute, paramPage: number, meta: IRPRequestMeta) =>
    async (dispatch: Dispatch): Promise<void> => {
        if (paramPage > 1) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {page, ...restQuery} = route.query;
            const firstPageUrl = appendQueryString(route.pathname, restQuery);
            dispatch(enable301ResponseState(firstPageUrl));
        } else {
            await dispatch(redirectOrEnable404ResponseState(route.pathname, meta));
        }
    };
