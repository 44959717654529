import type {Dispatch} from "redux";

import {escape} from "@pg-mono/nodash";

import {IMetaDataStore, rpCanonicalUrl} from "../reducers/meta_data_reducer";
import {setClientMetaData} from "../utils/set_client_meta_data";

export const UPDATE_META_DATA = "metaData/UPDATE_META_DATA";

export interface IUpdateMetaDataAction extends IMetaDataStore {
    type: typeof UPDATE_META_DATA;
}

export type MetaDataDispatch = Dispatch<IUpdateMetaDataAction>;

export interface IMetaDataOptions {
    title: string;
    description: string;
    pathname: string;
    prev?: string;
    next?: string;
    robots?: string;
    dimensionRegion?: string;
    gtmPage?: string;
    ogImage?: string;
    ogTitle?: string;
    ogType?: string;
    ogUrl?: string;
    ogPublishDate?: string;
    customCanonicalPath?: string;
    pageNumber?: number;
    hreflang?: {language_code: string; slug: string}[];
    lang?: "uk" | "pl";
}

export const updateMetaData = (metaData: IMetaDataOptions): IUpdateMetaDataAction => {
    const {
        title,
        description,
        pathname,
        prev,
        next,
        pageNumber,
        dimensionRegion,
        robots,
        gtmPage,
        ogImage,
        ogTitle,
        ogType,
        ogPublishDate,
        customCanonicalPath,
        hreflang,
        lang = "pl"
    } = metaData;

    const escapeTitle = escape(title);
    const escapeDescription = escape(description);
    let escapeCanonical = escape(`${rpCanonicalUrl}${pathname}`);
    if (customCanonicalPath) {
        // priority custom canonical path
        escapeCanonical = escape(`${customCanonicalPath.includes(rpCanonicalUrl) ? "" : rpCanonicalUrl}${customCanonicalPath}`);
    } else if (pageNumber && pageNumber > 1) {
        escapeCanonical += escape(`?page=${pageNumber}`);
    }
    const escapePrev = prev && escape(prev);
    const escapeNext = next && escape(next);
    const dimensionRegions = escape(dimensionRegion);
    // advised practice to set ogType "article" only to blog type content views and set "website" to the rest of pages
    const ogTypeData = ogType || "website";

    const newMetaData: IMetaDataStore = {
        title: escapeTitle,
        description: escapeDescription,
        canonical: escapeCanonical,
        prev: escapePrev,
        next: escapeNext,
        robots,
        dimensionRegion: dimensionRegions,
        gtmPage,
        ogDescription: escapeDescription,
        ogTitle: ogTitle || escapeTitle,
        ogImage,
        ogType: ogTypeData,
        ogUrl: escapeCanonical,
        ogPublishDate,
        lang,
        ...(hreflang && {hreflang})
    };

    // update DOM
    setClientMetaData(newMetaData);

    return {type: UPDATE_META_DATA, ...newMetaData};
};
