import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetail} from "../../offer/detail/types/IOfferDetail";

const FETCH_MY_OFFERS_OFFERS_LIST = "my_offers/FETCH_MY_OFFERS_OFFERS_LIST";
export const fetchMyOffersOffersListTypes = createRequestActionTypes(FETCH_MY_OFFERS_OFFERS_LIST);

export const fetchMyOffersOffersList = (ids: number[]) => async (dispatch: Dispatch) => {
    dispatch({type: fetchMyOffersOffersListTypes.start});

    if (!ids.length) {
        return dispatch({type: fetchMyOffersOffersListTypes.success, result: []});
    }

    Promise.all(
        ids.map((id) => {
            const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId: id});

            return getRequest({}, url);
        })
    ).then((response: IOfferDetail[]) => {
        dispatch({type: fetchMyOffersOffersListTypes.success, result: response});
    });
};
