import type {Dispatch, UnknownAction} from "redux";

import {enable404ResponseState} from "@pg-mono/response-state";

import {IUpdateMetaDataAction, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {title404Error} from "../../meta_data/utils/get_page_title";
import {description404Error} from "../../meta_data/utils/page_description";

export const rpEnable404ResponseState = (pathname: string) => async (dispatch: Dispatch<IUpdateMetaDataAction | UnknownAction>) => {
    dispatch(updateMetaData({title: title404Error, description: description404Error, pathname}));

    dispatch(enable404ResponseState());
};
