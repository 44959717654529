import {BRAND_NAME} from "../../app/constants/company";

export const getPageTitle = (title: string, page?: number, branding = true) => {
    const result: string[] = [title];

    // Append information about current page
    if (page && page > 1) {
        result.push(`Strona ${page}`);
    }

    // Append branding title text
    if (branding) {
        result.push(BRAND_NAME);
    }
    // Return array elements joined with dash
    return result.join(" - ");
};

export const title404Error = getPageTitle("404 - Nie znaleziono strony");
