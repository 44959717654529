import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

export enum ArticleCommentStatus {
    NEW = 1,
    IN_MODERATION = 2,
    ACCEPTED = 3,
    DENIED = 4
}

const FETCH_ARTICLE_COMMENT_LIST = "article/FETCH_ARTICLE_COMMENT_LIST";
export const fetchArticleCommentListTypes = createRequestActionTypes(FETCH_ARTICLE_COMMENT_LIST);

export interface IArticleComment {
    article: number;
    author: {
        name: string;
    } | null;
    children: IArticleComment[];
    create_date: string;
    id: number;
    nick_name: string | null;
    parent: number | null;
    status: ArticleCommentStatus;
    text: string;
    user: {
        name: string;
    } | null;
}

interface IArticleCommentListResponse extends IPaginatedResponse {
    results: IArticleComment[];
}

const fetchArticleCommentList =
    (meta: IRPRequestMeta = {}, articleId: number) =>
    (dispatch: Dispatch) => {
        const articleCommentListApiLink = apiV2ListLink.comments.list(Scenario.ARTICLE_DETAIL_COMMENTS, {articleId});

        dispatch({type: fetchArticleCommentListTypes.start});

        const url = appendQueryString(articleCommentListApiLink, {article_id: articleId, page_size: 1000});

        return getRequest(meta, url)
            .then((json: IArticleCommentListResponse) => {
                dispatch({type: fetchArticleCommentListTypes.success, result: json.results});
                return json.results;
            })
            .catch((err) => console.log({err}));
    };

export const fetchArticleCommentListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const articleId = parseInt(ctx.match.params.articleId, 10);
    if (!Number.isFinite(articleId)) {
        return false;
    }
    return dispatch(fetchArticleCommentList(ctx.meta, articleId));
};
