import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOnList404} from "../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {OfferDisplayType} from "../../offer/helpers/OfferDisplayType";
import {IPromotionListResponse} from "../interfaces/IPromotionListResponse";

const PROMOTION_LIST = "promotion_list/fetch";
export const fetchPromotionListTypes = createRequestActionTypes(PROMOTION_LIST);

export const PROMOTION_LIST_PAGE_SIZE = 6;

// additional parameters to describe what offers should be shown in promotion-list
const promotionListOfferConstrains = {
    limited_presentation: "False",
    display_type: [OfferDisplayType.FOR_SALE],
    for_sale: "True"
};

export const fetchPromotionListAtRoute =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug: string}, Record<string, string | string[]>>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const {prevResult: query} = ctx;

        const {latestQuery, requestState} = getState().promotionList;

        if (requestState !== RequestState.None && isEqual(latestQuery, query)) {
            return Promise.resolve(); // prevent fetching - same query
        }

        dispatch({type: fetchPromotionListTypes.start, latestQuery: query});

        const page = safelyParsePage(ctx.route.query.page);

        const fullQuery = {
            ...query,
            ...promotionListOfferConstrains,
            page_size: PROMOTION_LIST_PAGE_SIZE
        };

        const url = appendQueryString(apiV2ListLink.promotion.list(Scenario.PROMOTION_LIST), fullQuery);

        return getRequest(ctx.meta, url)
            .then((json: IPromotionListResponse) => {
                const result = {
                    promotions: json.results,
                    count: json.count,
                    page: json.page,
                    page_size: json.page_size
                };

                dispatch({type: fetchPromotionListTypes.success, result});
                return json;
            })
            .catch(
                catch400(async (error) => {
                    dispatch({type: fetchPromotionListTypes.error, error: error.appError});
                })
            )
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchPromotionListTypes.error, error});
                    // Page from outside available range - redirect to first page
                    if (page) {
                        dispatch(redirectOnList404(ctx.route, page, ctx.meta));
                    }
                })
            );
    };
